import React, { Component } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
// import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";

// Import custom components
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import { connect } from "react-redux";
import LogoImage from "./common/logo";
import * as AUTH from "../../../api/auth";
import * as PROFILE from "../../../api/profile";
import { updateToken, saveUser, addMyCart, refreshProfile } from "../../../actions";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import * as PRODUCTS from "../../../api/product";
import defaultAvatar from "../../../assets/images/defaultAvatar.jpg";

const WAIT_INTERVAL = 1000;
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      value: "",
      suggestions: [],
      searchLoading: false,
      name: "",
      mobileSearch: false,
    };
  }

  /*=====================
         Pre loader
         ==========================*/
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  getUser() {
    PROFILE.getProfile()
      .then((res) => {
        this.setState({ name: res.data.name + " " + res.data.lname, avatarUrl: res.data.image });
        this.props.saveUser({
          id: res.data.id,
          name: res.data.fname + " " + res.data.lname,
          role: res.data.dealer ? res.data.dealer : 0,
        });
        this.props.refreshProfile(false);
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
        }
      });
  }

  resize() {
    this.setState({ windowWidth: window.innerWidth });
    if (window.innerWidth > 991) {
      this.setState({ mobileSearch: false });
    }
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    var header = document.getElementById("sticky");
    var sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      document.getElementById("content-wrapper").style = window.innerWidth < 578 ? "padding-top:80px" : "padding-top:98px";
      document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("content-wrapper").style = "padding-top:unset";
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    // store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
      document.getElementById("BODY").style.overflowY = "hidden";
    }
  }
  openSearch = () => {
    this.setState({ mobileSearch: true });
  };

  closeSearch() {
    this.setState({ mobileSearch: false });
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  logout() {
    AUTH.logout(this.props);
    toast.info("Logout Successful!", { autoClose: 3000, position: "top-center" });
    setTimeout(() => this.props.history.replace("/login"), 500);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => {
    if (suggestion.name) {
      this.closeSearch();
      this.props.history.push("/product/" + suggestion.slug);
    }
    return "";
  };

  getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength > 1) {
      let params = {
        value: value.trim().toLowerCase(),
        per_page: 10,
        page: 1,
      };
      await PRODUCTS.searchProduct(params).then((res) => {
        this.setState({
          suggestions: res.http_code == 200 && res.data.list ? res.data.list : [{ name: "No Result" }],
          searchLoading: false,
        });
      });
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    clearTimeout(this.timer);
    this.setState({ searchLoading: true });
    if (value.length > 1) {
      this.timer = setTimeout(() => {
        this.getSuggestions(value);
      }, WAIT_INTERVAL);
    } else {
      this.setState({ searchLoading: false });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  searchProducts(value) {
    if (value && value.length > 0) {
      this.setState({ searchLoading: false, value: "", mobileSearch: false });
      this.props.history.push("/product-search?value=" + value);
    }
  }

  render() {
    const { value, suggestions, searchLoading, windowWidth, mobileSearch, avatarUrl } = this.state;
    const inputProps = {
      placeholder: "Search product...",
      value,
      onChange: this.onChange,
      onKeyDown: (event) => (event.key == "Enter" ? this.searchProducts(value) : {}),
    };
    const { token, isDealer, profile, user } = this.props;
    return (
      profile ? this.getUser() : null,
      (
        <>
          <header id="sticky" className="sticky header-2 header-6 light-theme-layout" style={{ height: "auto" }}>
            {this.state.isLoading ? <Pace color="#27ae60" /> : null}
            {/* <div className="mobile-fix-option"></div> */}
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="main-menu border-section border-top-0">
                    <div className="menu-left">
                      <div className="navbar">
                        <a href="javascript:void(0)" onClick={this.openNav}>
                          <div className="bar-style">
                            <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                          </div>
                        </a>
                        {/*SideBar Navigation Component*/}
                        <SideBar />
                      </div>
                      <div className="layout2-logo">
                        <LogoImage from={"header"} logo={this.props.logoName} windowWidth={windowWidth} />
                      </div>
                    </div>
                    {mobileSearch || windowWidth > 991 ? (
                      <div className="form_search" style={searchBox}>
                        <Autosuggest
                          theme={autosuggestBox}
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                          onBlur={() => this.setState({ searchLoading: false })}
                          className="nav-search nav-search-field"
                        />
                        {searchLoading ? <div style={loadingBar} /> : null}
                        {mobileSearch ? (
                          <i className="fa fa-close" onClick={() => this.closeSearch()}></i>
                        ) : (
                          <i className="fa fa-search" onClick={() => this.searchProducts(value)}></i>
                        )}
                      </div>
                    ) : null}
                    {mobileSearch ? null : (
                      <div className="menu-right pull-right">
                        <div className="icon-nav">
                          <ul>
                            <li className="onhover-div" hidden={window.innerWidth > 991}>
                              <div>
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                                  onClick={this.openSearch}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </li>
                            <li className="onhover-div pl-0 ml-4">
                              <div>
                                <img
                                  ref={(avatarImg) => (this.avatarImg = avatarImg)}
                                  src={avatarUrl ? avatarUrl : defaultAvatar}
                                  onError={() => (this.avatarImg.src = defaultAvatar)}
                                  className="nav-avatar"
                                  alt=""
                                  hidden={!token}
                                />
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/icon/users.png`}
                                  className="img-fluid"
                                  hidden={token}
                                  alt=""
                                />
                              </div>
                              <div className="show-div setting" style={{ minWidth: 190 }}>
                                {token ? (
                                  <ul className="onhover-show-div">
                                    <li className="mb-3">
                                      <Link to={"/members/account"} className="d-inline-flex align-items-center">
                                        <img
                                          src={avatarUrl ? avatarUrl : defaultAvatar}
                                          ref={(img) => (this.avatar = img)}
                                          onError={() => (this.avatar.src = defaultAvatar)}
                                          style={avatarStyle}
                                        />
                                        <div style={{ fontSize: 15 }}>{user.name}</div>
                                      </Link>
                                    </li>
                                    <hr />
                                    <li className="mb-3">
                                      <Link to={`${process.env.PUBLIC_URL}/members/dashboard`} data-lng="en">
                                        {isDealer ? "Dashboard" : "My Account"}
                                      </Link>
                                    </li>
                                    <li className="mb-3">
                                      <Link to={"/members/order"} data-lng="en">
                                        My Orders
                                      </Link>
                                    </li>
                                    <div className="account-sidebar">
                                      {isDealer ? (
                                        <>
                                          <li className="mb-3">
                                            <Link to={"/members/member-list"}>Member List</Link>
                                          </li>
                                          <li className="mb-3">
                                            <Link to={"/members/commission-list"}>My Commission</Link>
                                          </li>
                                        </>
                                      ) : null}
                                      <li className="mb-3">
                                        <Link to={"/members/account"} data-lng="en">
                                          My Profile
                                        </Link>
                                      </li>
                                      <li className="mb-3">
                                        <Link to={"/members/address"} data-lng="en">
                                          Address Book
                                        </Link>
                                      </li>
                                      <li className="mb-3">
                                        <Link to={"/members/edit-pw"} data-lng="en">
                                          Edit Password
                                        </Link>
                                      </li>
                                    </div>
                                    <li>
                                      <a
                                        type="button"
                                        style={{ WebkitAppearance: "none" }}
                                        onClick={() => this.logout()}
                                        data-lng="en">
                                        Logout
                                      </a>
                                    </li>
                                  </ul>
                                ) : (
                                  <ul className="onhover-show-div setting">
                                    <li className="mb-3">
                                      <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">
                                        Login
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/register`} data-lng="en">
                                        Register
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                              </div>
                            </li>
                            {/*Header Cart Component */}
                            <CartContainer THIS={this} windowWidth={windowWidth} />
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
        </>
      )
    );
  }
}

const avatarStyle = {
  width: 30,
  height: 30,
  borderRadius: 30 / 2,
  marginLeft: -20,
  marginRight: 8,
  objectFit: "cover",
};

const searchBox = {
  overflow: "visible",
  backgroundColor: "#fff",
  width: "500px",
};

const loadingBar = {
  position: "absolute",
  right: "14px",
  top: "8px",
  border: "none",
  backgroundColor: "transparent",
  width: "50px",
  height: "40px",
  backgroundRepeat: "no-repeat",
  margin: "0 auto",
  backgroundImage: `url("/assets/images/loading.gif")`,
};

const autosuggestBox = {
  container: {
    position: "relative",
    height: "auto",
  },
  input: {
    width: "87%",
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    color: "#696969",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 50,
    width: "100%",
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 14,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 99,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    width: "100%",
    padding: "5px 15px",
  },
  suggestionHighlighted: {
    backgroundColor: "#ddd",
  },
};

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
  refreshProfile: (profile) => dispatch(refreshProfile(profile)),
  saveUser: (user) => dispatch(saveUser(user)),
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    profile: state.data.profile,
    user: state.data.user,
    isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
