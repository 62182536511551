import * as AUTH from './auth'
const axios = require("axios");

export async function getProfile() {
  let accessToken = await AUTH.getAccessToken();
  let url=process.env.API_URL + process.env.API_PREFIX +'/profile'
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        const { data } = response;

        if(data.http_code=='200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function updateProfile(data) {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/profile", data,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function changePw(data) {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/profile/edit-pw", data,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getAddress() {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .get(process.env.API_URL + process.env.API_PREFIX +"/profile/address",{
        headers: {
          Accept: "application/json",
          'token': accessToken,
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getDefaultShippingAddress() {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .get(process.env.API_URL + process.env.API_PREFIX +"/profile/d-shipping-address",{
        headers: {
          Accept: "application/json",
          'token': accessToken,
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getDefaultBillingAddress() {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .get(process.env.API_URL + process.env.API_PREFIX +"/profile/d-billing-address",{
        headers: {
          Accept: "application/json",
          'token': accessToken,
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getAddressDetail(id) {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .get(process.env.API_URL + process.env.API_PREFIX +"/profile/address/"+id,{
        headers: {
          Accept: "application/json",
          'token': accessToken,
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
        console.log(error);
      })
  });
}

export async function addAddress(data) {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/profile/add-address", data,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function editAddress(data, id) {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/profile/edit-address/"+id, data,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function deleteAddress(id) {
  let accessToken = await AUTH.getAccessToken();
  
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/profile/delete-address/"+id,null,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        const { data } = response;

        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getWallet() {
  let accessToken = await AUTH.getAccessToken();

  return new Promise((resolve, reject) => {
    axios
      .get(process.env.API_URL + process.env.API_PREFIX +"/user/wallet",{
        headers: {
          Accept: "application/json",
          'token': accessToken,
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
        console.log(error);
      })
  });
}

export async function topUpWallet(data) {
  let accessToken = await AUTH.getAccessToken();
  
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/user/wallet",data,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        const { data } = response;

        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
        console.log(error);
      })
  });
}

