import * as AUTH from "./auth";
import { convertToCartItem } from "../services/index";
import { addMyCart, updateCartInfo } from "../actions/index";
import store from "../store";
import { toast } from "react-toastify";
const axios = require("axios");

export async function getCart() {
  let header = {
    Accept: "application/json",
  };
  if (AUTH.getAccessToken()) header.token = AUTH.getAccessToken();
  let url = process.env.API_URL + process.env.API_PREFIX + "/cart";
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function addCart(data) {
  let header = {
    Accept: "application/json",
  };
  if (AUTH.getAccessToken()) header.token = AUTH.getAccessToken();
  let url = process.env.API_URL + process.env.API_PREFIX + "/cart/add";

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: header,
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function removeCart(data) {
  let header = {
    Accept: "application/json",
  };
  if (AUTH.getAccessToken()) header.token = AUTH.getAccessToken();
  let url = process.env.API_URL + process.env.API_PREFIX + "/cart/remove";

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: header,
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export function getLatestCart(from) {
  getCart()
    .then(async (res) => {
      if (res.data && res.data.guest_checkout !== undefined) {
        store.dispatch(
          updateCartInfo({
            guestCheckout: res.data.guest_checkout,
            requiredEmail: res.data.checkout_required_email,
          })
        );
      }

      if (res.data && res.data.cart !== null) {
        let cartList = [];
        let dbCart = res.data.cart;
        for (let i = 0; i < dbCart.length; i++) {
          // get subtotal price here
          let total =
            dbCart[i].dealer_price && parseFloat(dbCart[i].dealer_price.total_price) > 0
              ? dbCart[i].dealer_price.total_price
              : dbCart[i].is_promotion == 1 && parseFloat(dbCart[i].price.pprice) > 0
              ? dbCart[i].price.total_promotion_price
              : dbCart[i].price.total_price;

          // subtotal add on extra price if hv
          total = parseFloat(total.replace(/[$,]+/g, "")) + (dbCart[i].selected_pov ? parseFloat(dbCart[i].selected_pov.total_extra_price) : 0);
          dbCart[i].sum = parseFloat(total).toFixed(2);

          cartList.push(convertToCartItem(dbCart[i], true));
        }
        store.dispatch(addMyCart(cartList));
      } else {
        store.dispatch(addMyCart([]));
      }
    })
    .catch((err) => {
      if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
        if (!from && from !== "checkout") toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
        AUTH.logout();
      }
    });
}
