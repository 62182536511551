import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    INCREMENT_QTY,
    DECREMENT_QTY, 
    ADD_MY_CART} from "../constants/ActionTypes";
import * as CART from '../api/cart'
import * as AUTH from '../api/auth'
import { toast } from 'react-toastify';

function updateCart(productId,productQty,option,props){
    let data = new FormData()
    data.append('pid',productId)
    data.append('qty',productQty)
    if(option)
        data.append('pov_id',option.id)
    CART.addCart(data).then(res=>{  //add to database
       
    }).catch(err=>{
        if(err.http_code==401 || (err.http_code==403 && err.message=='token expired')){
            AUTH.logout(props)
            toast.error("Session Expired", { autoClose: 3000 ,position:'top-center'});
            if(props && props.history)
                props.history.replace('/login')
            else
                window.location = "/";
        }
    })
}

export default function cartReducer(state = {
    cart: []
}, action) {
    switch (action.type) {
        case ADD_MY_CART:
            const cart = action.cart
            return { ...state, cart }
        case ADD_TO_CART:
            const productId = action.product.pid
            const productQty = parseInt(action.product.qty,10)
            const optionId = action.product.selected_pov?action.product.selected_pov.id:null
            if (state.cart.find(product => (product.selected_pov?
                product.pid === productId && product.selected_pov.id==optionId
                :product.pid === productId ))) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.selected_pov?
                        product.pid === productId && product.selected_pov.id==optionId
                        :product.pid === productId  ) {
                            if(parseInt(action.qty,10)!==0){ // Increment qty
                                updateCart(productId,product.qty+parseInt(action.qty,10),product.selected_pov,action.props)
                                cartAcc.push({ ...product, qty: product.qty+parseInt(action.qty,10), 
                                    sum: (product.finalPrice)*(product.qty+action.qty) }) 
                            }else{  //replace qty
                                updateCart(productId,productQty,product.selected_pov,action.props)
                                cartAcc.push({ ...product, qty: productQty, sum: (product.finalPrice)*(productQty) })
                            }
                    } else {
                        cartAcc.push(product)
                    }
                    return cartAcc
                }, [])
                return { ...state, cart }
            }  

            updateCart(productId,action.qty,action.product.selected_pov,action.props)
            return { ...state, cart: [...state.cart, { ...action.product, qty: action.product.qty, 
                sum: (action.product.finalPrice)*action.qty }].slice(0) }
        case DECREMENT_QTY:
            const product_ID = action.product.pid
            const option_ID = action.product.selected_pov?action.product.selected_pov.id:null
            updateCart(product_ID,action.product.qty-1,action.product.selected_pov,action.props)
            if (state.cart.find(product => (product.selected_pov?
                product.pid === product_ID && product.selected_pov.id==option_ID
                :product.pid === product_ID))) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.qty>=1 && (product.selected_pov?
                        product.pid === product_ID && product.selected_pov.id==option_ID
                        :product.pid === product_ID)) {
                        cartAcc.push({ ...product, qty: product.qty-1, 
                            sum: (product.finalPrice)*(product.qty-1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, 
                sum: action.product.finalPrice *action.qty }] }

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(item => (action.product.selected_pov?
                    item.pid !== action.product.pid || 
                    item.selected_pov.id!==action.product.selected_pov.id
                    :item.pid !== action.product.pid))
            }
        default:
    }
    return state;
}
