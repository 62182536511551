import * as CART from '../api/cart'
import * as AUTH from '../api/auth'
import * as types from '../constants/ActionTypes'
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const updateCartInfo = (info) => ({
    type: "CART_INFO",
    info,
  });

export const refreshProfile = (isUpdating) => (dispatch) => {
    if (isUpdating)
      dispatch({
        type: "UPDATE_PROFILE",
      });
    else {
      dispatch({
        type: "FINISH_UPDATE",
      });
    }
  };
  
  export const saveUser = (user) => ({
    type: 'STORE_USER',
    user,
  });
  
  export const updateToken = (token) => (dispatch) => {
    dispatch(saveToken(token ? token : null));
  };
  

export const saveToken = (token) => ({
  type: types.UPDATE_AUTH,
  token,
});

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});

export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
})

export const addMyCart = (cart) => ({
    type: types.ADD_MY_CART,
    cart
});

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product,qty,props) => (dispatch) => {
    dispatch(addToCartUnsafe(product, qty,props))
}

export const addToCartUnsafe = (product, qty,props) => ({
    type: types.ADD_TO_CART,
    product,
    qty,
    props
});

export const removeFromCart = (product,props) => (dispatch) => {
    let data = new FormData()
    data.append('pid',product.pid)
    if(product.selected_pov)
        data.append('pov_id',product.selected_pov.id)

    CART.removeCart(data).then(res=>{
    }).catch(err=>{
        if(err.http_code==401 || (err.http_code==403 && err.message=='token expired')){
            AUTH.logout()
            toast.error("Session Expired", { autoClose: 3000 ,position:'top-center'});
            if(props && props.history)
                props.history.push('/')
        }
    })

    dispatch({
        type: types.REMOVE_FROM_CART,
        product
    })
};
export const incrementQty = (product,qty,props) => (dispatch) => {
    dispatch(addToCartUnsafe(product, qty,props))

}
export const decrementQty = (product,props) => (dispatch) => {
    dispatch({
    type: types.DECREMENT_QTY,
    product,props})
};


//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => (dispatch) => {
    toast.error("Item Removed from Wishlist");
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id
    })
};


//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe= (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});

