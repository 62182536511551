import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./common/index.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import TrendingProducts from "../components/home/products";
import Modal from "react-responsive-modal";
import Categories from "../components/home/categories";
import ShippingModal from "../components/home/shippingModal";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./custom-slick.css";
// Import custom components
import { svgFreeShipping, svgservice, svgoffer } from "../services/script";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      addressModal: false
    };
  }

  async componentWillMount() {
    document.getElementById("BODY").style.touchAction = "manipulation";
  }

  async componentDidMount() {
    if (!this.props.isLoading) {
      let hvPopBefore = await localStorage.getItem("popUp");
      if (!hvPopBefore) {
        this.setPopUpToStorage();
      } else {
        let popDateTime = JSON.parse(hvPopBefore);
        if (new Date(moment(popDateTime).add(1, "days")) < new Date()) {
          this.setPopUpToStorage();
        }
        let ship = JSON.parse(localStorage.getItem("shipping"));
        if(ship === null){
          this.setState({
            addressModal: true
          })
        }
      }
    }
  }

  setPopUpToStorage() {
    this.setState({ open: true }, () => {
      localStorage.setItem("popUp", JSON.stringify(new Date()));
    });
  }

  componentWillUnmount() {
    document.getElementById("BODY").style.touchAction = null;
  }

  onCloseModal = () => {
    this.setState({ open: false });
    let ship = JSON.parse(localStorage.getItem("shipping"));
    if(ship === null){
      this.setState({
        addressModal: true
      })
    }
  };

  render() {
    const { bannerImg, history,isLoading } = this.props;

    return (
      <div>
        <Helmet>
          <title>Ant Internet</title>
        </Helmet>
        <section className="p-0">
          <Slider className="slide-1 home-slider" autoplay dots>
          {bannerImg && bannerImg.length > 0 ? (
              bannerImg.map((img) => {
                return (
                  <div key={img.id}>
                    <div
                      className="home home39 text-center"
                      style={{
                        backgroundImage: `url(${img.filename}),url(${process.env.PUBLIC_URL}/assets/images/home-banner/banner-no-image.jpg)`,
                      }}>
                      <div className="container">
                        <div className="row">
                          <div className="col">
                            <div className="slider-contain">{/* can put text here... */}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div className="home home39 text-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home-banner/banner-no-image.jpg)` }}>
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="slider-contain"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Slider>
        </section>
        {/*collection banner layout*/}
        <section className="banner-padding absolute-banner pb-0">
          <div className="container absolute-bg">
            <div className="service p-0">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                    <div className="media-body">
                      <h4>Shipping!</h4>
                      <p>We deliver 7 days a week from 8am-8pm</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                    <div className="media-body">
                      <h4>Great Deal, High Value!</h4>
                      <p>Direct sourcing from farm</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                    <div className="media-body">
                      <h4>Dedicated Sales Coordinator</h4>
                      <p>Assist your shopping experience</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*collection banner layout end*/}
        {!isLoading ? (
          <>
            <TrendingProducts title=" SALE" highlightTitle="SHOCKING" subtitle="" history={history} />
            <Categories title="TRENDING CATEGORIES" subtitle="" subcategoryTitle="OTHER CATEGORIES" history={history} />
          </>
        ) : null}
      </div>
    );
  }
}

const closeBtn = {
  width: "100px",
  alignSelf: "center",
};

const infoText = {
  fontSize: 17,
  marginVertical: 10,
};

const modalBox = {
  padding: 30,
  textAlign: "center",
  borderWidth: 1,
};

export default Home;
