import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SlideUpDown } from "../../../services/script";
import LogoImage from "../headers/common/logo";
import * as General from "../../../api/general";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      footerList: props.footerMenu,
      // footer info
      address1: "",
      address2: "",
      officeno: "",
      email: "",
      faxno: "",
      mobileno: "",
      mobileView: false,
      whatsapp: "",
      currentYear: null
    };
  }

  resize() {
    if (window.innerWidth <= 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getGeneralInfo();
    this.getCurrentYear();
  }

  getGeneralInfo() {
    General.getSystemSettingsGeneral()
      .then((res) => {
        this.setState({
          address1: res.data.address1.value,
          address2: res.data.address2.value,
          officeno: res.data.office_no_1.value,
          mobileno: res.data.mobile_no_1.value,
          faxno: res.data.fax_no.value,
          email: res.data.e_mail.value,
          whatsapp: res.data.whatsapp ? res.data.whatsapp.value : '',
        });
      })
      .catch((err) => {});
  }

  getCurrentYear() {
    var year = new Date().getFullYear();
    this.setState({
      currentYear: year
    })
  }

  render() {
    const { footerMenu } = this.props;
    const whatsappNumber = this.state.whatsapp.replace(/\D/g, '');
    return (
      <footer className="footer-light">
        <a hidden={whatsappNumber.length == 0} href={"https://api.whatsapp.com/send?phone=" + whatsappNumber} className="float chat-float" target="_blank">
          <i className="fa fa-whatsapp"></i>
        </a>
        <section className="section-b-space">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-5 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4>about</h4>
                </div>
                <div className="footer-contant">
                  <div className="footer-logo">
                    <LogoImage from={"footer"} logo={this.props.logoName} />
                  </div>
                  <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <br></br>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>why we choose</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      {footerMenu && footerMenu.length > 0
                        ? footerMenu.map((menu) => {
                            return (
                              <li key={menu.id}>
                                <Link to={"/" + menu.url}>{menu.name}</Link>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>store information</h4>
                  </div>
                  <div className="footer-contant">
                    <ul className="contact-list">
                      <li>
                        <i className="fa fa-map-marker" />
                        {this.state.address1} {this.state.address2}
                      </li>
                      <li>
                        <i className="fa fa-phone" />
                        Call Us: {this.state.officeno ? <a href={"tel:" + this.state.officeno}>{this.state.officeno}</a> : null}
                       { this.state.officeno.length > 0 && this.state.mobileno.length > 0 ? (
                              <span>
                                <br/>
                                / {" "}
                              </span>
                            ) : null}
                        {this.state.mobileno ? (

                            <a href={"tel:" + this.state.mobileno}>{this.state.mobileno}</a>
                        ) : (
                          ""
                        )}
                      </li>
                      <li>
                        <i className="fa fa-envelope-o" />
                        Email Us: <span style={{ textTransform: "lowercase" }}>{this.state.email}</span>
                      </li>
                      {this.state.faxno ? (
                        <li>
                          <i className="fa fa-fax" />
                          Fax: {this.state.faxno}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="sub-title1">
                  <div className="footer-title">
                    <h4>payment options</h4>
                  </div>
                  <div className="footer-contant">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`}
                      alt=""
                      style={{ width: "45px", height: "auto", marginRight: "10px" }}
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`}
                      alt=""
                      style={{ width: "45px", height: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer">
          <div className="container">
          <hr></hr>
            <div className="footer-end row">

                <p className="col-md-6 col-sm-12">
                  <i className="fa fa-copyright" aria-hidden="true" /> {this.state.currentYear} Ant Internet Sdn Bhd. All rights reserved.
                </p>

                <p className="col-md-6 col-sm-12">
                  <a id="antinternet" target="_blank" href="https://ant-internet.com" className="c-pointer">
                    Design and Development by Ant Internet Sdn Bhd
                  </a>
                </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
