import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateToken,  } from "../../../actions";
import * as GENERAL from "../../../api/general";
import * as Profile from "../../../api/profile";
import { toast } from 'react-toastify';
import * as AUTH from '../../../api/auth';
const stateArr = GENERAL.getStateList();

class AddressItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  getState(state) {
    for (let i = 0; i < stateArr.length; i++) {
      if (state == Object.keys(stateArr[i]).toString()) return Object.values(stateArr[i]).toString();
    }
  }

  resize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  makeDefault(field, data) {
    const addressData = new FormData();
    addressData.append("title", data.title);
    addressData.append("fname", data.fname);
    // addressData.append("lname", data.lname);
    addressData.append("street1", data.street1);
    addressData.append("street2", data.street2);
    addressData.append("city", data.city);
    addressData.append("zip", data.zip);
    addressData.append("state", data.state);
    addressData.append("country", data.country);
    addressData.append("mobileno", data.mobile_no);
    if (field === "b") {
      addressData.append("billing", 1);
      addressData.append("shipping", data.shipping);
      this.setState({
        defaultMsg: "Make default billing address success!",
      });
    } else if (field === "s") {
      addressData.append("billing", data.billing);
      addressData.append("shipping", 1);
      this.setState({
        defaultMsg: "Make default shipping address success!",
      });
    }
    Profile.editAddress(addressData, data.id)
      .then((res) => {
        // toast.success(this.state.defaultMsg, { autoClose: 3000 });
        window.location.reload();
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        }
        toast.error("Make default shipping address failed!");
      });
  }

  deleteAddress(id, billing, shipping) {
    if (billing === "1" || shipping === "1") {
      toast.error("Default address cannot be delete", { autoClose: 3000, position: "top-center" });
    } else {
      Profile.deleteAddress(id)
        .then((res) => {
          if (res.errors) {
            toast.errors("failed", { autoClose: 3000, position: "top-center" });
          }
          // toast.success("Delete address success!", { autoClose: 3000 });
          window.location.reload();
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          }
          console.log(err);
          toast.success("Delete address failed!", { autoClose: 3000, position: "top-center", position: "top-center" });
        });
    }
  }

  render() {
    const {  address,from } = this.props;
    return (
      <div style={orderBox}>
        <div>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-lg-6 col-6">
              {address.shipping === "1" && address.billing === "0" ? (
                <span style={{ float: "left", padding: "5px", margin: "2px" }} className="badge badge-info">
                  Default Shipping
                </span>
              ) : (
                ""
              )}
              {address.billing === "1" && address.shipping === "0" ? (
                <span style={{ float: "left", padding: "5px", margin: "2px" }} className="badge badge-success">
                  Default Billing
                </span>
              ) : (
                ""
              )}
              {address.shipping === "0" && address.billing === "0" ? "" : ""}
              {address.shipping === "1" && address.billing === "1" ? (
                <div>
                  <span style={{ float: "left", padding: "5px", margin: "2px" }} className="badge badge-info">
                    Default Shipping
                  </span>
                  <span
                    style={{ float: "left", padding: "5px", margin: "2px", marginLeft: "10px" }}
                    className="badge badge-success">
                    Default Billing
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            {from=='downline'?null
            :
            <div className="col-lg-6 col-6">
              <button onClick={() => this.deleteAddress(address.id, address.billing, address.shipping)} style={deleteIcon}>
                <i className="fa fa-trash"></i>
              </button>
              <Link to={`${process.env.PUBLIC_URL}/members/address-edit/${address.id}`}>
                <i style={editIcon} className="fa fa-edit"></i>
              </Link>
            </div>
            }
          </div>
          <hr style={{ marginBottom: "10px", marginTop: "0px" }}></hr>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <p>Full Name: </p>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-9 col-6">
            <p>
              {address.fname}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <p>Mobile Number: </p>
          </div>
          <div className="col-lg-9 col-sm-9 col-6">
            <p>{address.mobile_no}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3">
            <p style={{ marginBottom: "0px" }}>Address: </p>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-9">
            <p style={{ marginBottom: "0px" }}>
              {address.street1}, {address.street2.length>0?address.street2+",":null} {address.city}, {address.zip}, {this.getState(address.state)}
            </p>
          </div>
        </div>
        {from=='downline'?null
        :
        <div className="row">
          <div className="col-lg-12">
            {address.shipping === "1" && address.billing === "0" ? (
              <div className="d-inline-flex" style={{ paddingRight: "15px", float: "right" }}>
                <div className="col-lg-12">
                  <button style={defaultBtn} onClick={() => this.makeDefault("b", address)}>
                    <div style={{ marginBottom: "-8px", paddingLeft: "11px" }}>
                      <p style={{ textAlign: "left", marginBottom: "0px", fontSize: "10px" }}>Make as Default</p>
                    </div>
                    <div className="text-theme" style={{ borderLeft: "1px solid #a1a1a1", paddingLeft: "10px" }}>
                      Billing Address
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {address.billing === "1" && address.shipping === "0" ? (
              <div className="d-inline-flex" style={{ paddingRight: "15px", float: "right" }}>
                <div className="col-lg-12">
                  <button style={defaultBtn} onClick={() => this.makeDefault("s", address)}>
                    <div style={{ marginBottom: "-8px", paddingLeft: "11px" }}>
                      <p style={{ textAlign: "left", marginBottom: "0px", fontSize: "10px" }}>Make as Default</p>
                    </div>
                    <div className="text-theme" style={{ borderLeft: "1px solid #a1a1a1", paddingLeft: "10px" }}>
                      Shipping Address
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {address.shipping === "0" && address.billing === "0" ? (
              <div style={{ float: "right" }}>
                <div className="d-inline-flex" style={{ marginRight: "15px" }}>
                  <button style={defaultBtn} onClick={() => this.makeDefault("s", address)}>
                    <div style={{ marginBottom: "-8px" }}>
                      <p style={{ textAlign: "left", marginBottom: "0px", fontSize: "10px" }}>Make as Default</p>
                    </div>
                    <div className="text-theme" style={{ marginRight: "10px" }}>
                      Shipping Address
                    </div>
                  </button>
                  <button style={defaultBtn} onClick={() => this.makeDefault("b", address)}>
                    <div className="text-theme" style={{ marginBottom: "-8px", paddingLeft: "11px" }}>
                      <p style={{ textAlign: "left", marginBottom: "0px", fontSize: "10px" }}>Make as Default</p>
                    </div>
                    <div className="text-theme" style={{ borderLeft: "1px solid #a1a1a1", paddingLeft: "10px" }}>
                      Billing Address
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        }
      </div>
    );
  }
}

const orderBox = {
  border: "1px solid #ddd",
  padding: "15px",
  marginBottom: "18px",
};

const editIcon = {
  float: "right",
  paddingTop: "5px",
  paddingRight: "10px",
  color: "#0f9916",
  fontSize: "15px",
};

const deleteIcon = {
  float: "right",
  color: "red",
  fontSize: "15px",
  paddingBottom: "5px",
  backgroundColor: "transparent",
  border: "0px",
};

const defaultBtn = {
  backgroundColor: "transparent",
  border: "0px",
  color: "#ff4c3b",
  marginTop: "10px",
};

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  user: state.data.user,
});

export default connect(mapStateToProps, { updateToken })(AddressItem);
