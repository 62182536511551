import React, { Component, Fragment } from "react";
import * as ORDER from "../../api/order";
import { connect } from "react-redux";
import { addMyCart, updateToken } from "../../actions/index";
import * as AUTH from "../../api/auth";
import { toast } from "react-toastify";
import moment from "moment";
import * as GENERAL from "../../api/general";

const stateArr = GENERAL.getStateList();
class orderSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderID: props.location.state ? props.location.state.orderID : 0,
      orderDetails: [],
      orderDate: props.location.state ? props.location.state.orderDate : '',
      order: props.location.state ? props.location.state.order : {},
      submitUrl: props.location.state ? props.location.state.submitUrl : null,
      submitMessage: props.location.state ? props.location.state.submitMessage : null,
      isGuest: props.location.state ? props.location.state.isGuest : 0,
      shippingInfo: {},
      shippingAddress: {},
      payment: {},
      bankName: "",
      bankAccName: "",
      bankAccNo: "",
      loading: true,
    };
  }

  componentDidMount() {
    const { order } = this.state;
    const { token } = this.props;
    if (token) {
      ORDER.getOrderDetail(this.state.orderID)
        .then((res) => {
          if (res.data) {
            this.setState(
              {
                order: res.data,
                orderDate: res.data.cdate,
                payment: res.data.payment,
                orderDetails: res.data.order_detail,
                shippingInfo: res.data.shipping_info,
                shippingAddress: res.data.shipping,
                state: stateArr.find((st) => st[res.data.shipping.shipping_state]),
              },
              () => {
                this.setState({ loading: false });
              }
            );
          }
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          }
        });
    } else {
      this.setState({
        orderDetails: order.product,
        orderDate: order.order_date,
        payment: {
          subtotal: order.subtotal,
          total_price: order.total,
        },
        shippingInfo: { shipping_fee: order.shipping_fee, packing: order.packing_fee ? 1 : 0, packing_fee: order.packing_fee },
        shippingAddress: order.shipping,
        loading: false,
      });
    }
    this.getBankInfo();
  }

  getBankInfo() {
    GENERAL.getSystemSettingsGeneral().then((res) => {
      this.setState({
        bankName: res.data.bank_name.value,
        bankAccName: res.data.bank_account_name.value,
        bankAccNo: res.data.bank_account_no.value,
      });
    });
  }

  render() {
    const { symbol, orderTotal } = this.props;
    const { orderDate, payment, isGuest, submitUrl, shippingAddress, shippingInfo, orderDetails, loading, orderID, order, bankAccName, bankAccNo, bankName, submitMessage } = this.state;

    var options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    var current = new Date();
    var next5days = new Date(Date.now() + 5 * 86400000);
    let CheckDate = current.toLocaleDateString("en-US", options).toString();
    let deliveryDate = next5days.toLocaleDateString("en-US", options).toString();
    return loading ? (
      <div className="loading-cls"></div>
    ) : orderDetails ? (
      <div>
        <section className="section-b-space light-layout" style={{ padding: 28 }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="success-text">
                  <i className="fa fa-check-circle" style={{ color: "orange" }} aria-hidden="true"></i>
                  <h2 style={{ textTransform: "capitalize" }}>one more step to complete your order</h2>
                  <p>
                    Make payment to account below and submit your payslip.
                  </p>
                  <h5 style={{ marginTop: "15px", fontWeight: "bold" }}>Bank Information</h5>
                  <p style={{ color: "#222222", marginBottom: "8px" }}>{bankName}</p>
                  <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccName}</p>
                  <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccNo}</p>
                  <hr style={{ marginBottom: "10px", marginTop: "10px" }}></hr>
                  <p>Order No : {orderID}</p>
                  <p style={{ textTransform: "none" }}>Order Date : {moment(orderDate).format("DD-MM-YYYY hh:mm a")}</p>
                  {isGuest ? (
                    submitUrl ? (
                      <button style={gotoList} onClick={() => (window.open(submitUrl, "_blank"))} className="btn">
                        { submitMessage }
                      </button>
                    ) : null
                  ) : (
                    <button style={gotoList} onClick={() => this.props.history.replace({ pathname: "/members/order-detail/INV" + order.id })} className="btn">
                      { submitMessage }
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-b-space pt-5">
          <div className="container">
            <div className="row" style={{ padding: "20px", border: "1px solid #dddddd" }}>
              <div className="col-lg-7">
                <div className="product-order">
                  <h3>your order details</h3>
                  <div className="row product-order-detail">
                    <div className="col-7 order_detail">
                      <div>
                        <h4>product name</h4>
                      </div>
                    </div>
                    <div className="col-2 order_detail">
                      <div>
                        <h4>quantity</h4>
                      </div>
                    </div>
                    <div className="col-3 order_detail">
                      <div style={{ textAlign: "right", minWidth: "100%" }}>
                        <h4>price</h4>
                      </div>
                    </div>
                  </div>
                  {orderDetails.map((item, index) => {
                    return (
                      <div className="row product-order-detail" key={index}>
                        <div className="col-7 order_detail">
                          <div>
                            <h5>
                              {item.pname ? item.pname : item.name} {item.attribute1 ? "(" + item.attribute1 + (item.attribute2 ? "+ " + item.attribute2 : "") : null}
                              {item.attribute1 ? ")" : null}
                            </h5>
                            {item.combo ? (
                              <div className="combo-list">
                                {item.combo.map((comboItem, index) => {
                                  return <p key={index}>- {comboItem}</p>;
                                })}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-2 order_detail">
                          <div style={{ textAlign: "center" }}>
                            <h5>{item.qty}</h5>
                          </div>
                        </div>
                        <div className="col-3 order_detail">
                          <div style={{ textAlign: "right", minWidth: "100%" }}>
                            <h5>
                              {symbol} {item.sub_total ? item.sub_total : parseFloat(item.subtotal).toFixed(2)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="total-sec">
                    <ul>
                      <li>
                        Subtotal{" "}
                        <span>
                          {symbol} {payment.subtotal}
                        </span>
                      </li>
                      {shippingInfo.packing ? (
                        <li>
                          Packing{" "}
                          <span>
                            {symbol} {shippingInfo.packing_fee}
                          </span>
                        </li>
                      ) : null}
                      <li>
                        Shipping{" "}
                        <span>
                          {symbol} {shippingInfo.shipping_fee}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="final-total">
                    <h3>
                      Total{" "}
                      <span>
                        {symbol} {payment.total_price}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row">
                  {shippingAddress ? (
                    <Fragment>
                      <div className="col-lg-12" style={{ border: "1px solid #dddddd", marginTop: "25px" }}>
                        <h5 style={{ marginTop: "15px", fontWeight: "bold" }}>Shipping Address</h5>
                        <p style={{ color: "#222222", marginBottom: "8px" }}>{shippingAddress.shipping_fname}</p>
                        <p style={{ color: "#222222" }}>{shippingAddress.shipping_mobile_no}</p>
                        <hr style={{ marginBottom: "10px", marginTop: "10px" }}></hr>
                        <p style={{ marginBottom: "8px" }}>
                          {shippingAddress.shipping_street1}, {shippingAddress.shipping_street2}
                        </p>
                        <p>
                          {shippingAddress.shipping_zip + " " + shippingAddress.shipping_city}{" "}
                          {stateArr.find((st) => st[shippingAddress.shipping_state] !== undefined)
                            ? stateArr.find((st) => st[shippingAddress.shipping_state] !== undefined)[shippingAddress.shipping_state]
                            : ""}
                        </p>
                      </div>
                      <div className="col-lg-12" style={{ border: "1px solid #dddddd", marginTop: "25px" }}>
                        <h5 style={{ marginTop: "15px", fontWeight: "bold" }}>Bank Information</h5>
                        <p style={{ color: "#222222", marginBottom: "8px" }}>{bankName}</p>
                        <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccName}</p>
                        <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccNo}</p>
                        <hr style={{ marginBottom: "10px", marginTop: "10px" }}></hr>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ) : (
      <section className="p-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="error-section">
                <h1>404</h1>
                <h2>page not found</h2>
                <a href="index.html" className="btn btn-solid">
                  back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const gotoList = {
  backgroundColor: "#00b346",
  color: "white",
  padding: "10px 20px",
};

const mapStateToProps = (state) => ({
  symbol: state.data.symbol,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  addMyCart: (cart) => dispatch(addMyCart(cart)),
  updateToken: (token) => dispatch(updateToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(orderSuccess);
