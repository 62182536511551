import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import { removeFromCart, incrementQty, decrementQty, updateToken, addMyCart, addToCart } from "../../actions";
import noImg from "../../assets/images/defaultProduct.jpg";
import * as CART from "../../api/cart";
import Modal from "react-responsive-modal";

class cartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: this.props.cartItems,
      openModal: false,
      removeItem: null,
      prevQty: 0,
    };
    CART.getLatestCart();
  }

  async componentWillMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  onOpenModal = (item) => {
    this.setState({ openModal: true, removeItem: item });
  };

  onCloseModal = (item) => {
    if (item.qty == "0") {
      let list = this.props.cartItems;
      list.find((cartItem) => cartItem.cid == item.cid);
      item.qty = this.state.prevQty;
      this.setState({ cartList: list });
    }
    this.setState({ openModal: false });
  };

  onChangeQty(e, item) {
    let prevQty = this.state.prevQty;
    let list = this.props.cartItems;
    let input = e.target.value;
    if (parseInt(input) < 0) {
      input = -input;
    }
    if (e.target.value == "") {
      prevQty = list.find((cartItem) => cartItem.cid == item.cid).qty;
    }
    list.find((cartItem) => cartItem.cid == item.cid).qty = e.target.value;
    item.qty = input;
    this.setState({ cartList: list, prevQty: prevQty }, () => {
      if (item.qty !== "0" && item.qty.length > 0) {
        this.props.addToCart(item, 0, this.props);
      }
      if (item.qty == "0") {
        this.onOpenModal(item);
      }
    });
  }

  checkInvalid(item) {
    if (!item.buyable || item.stockStatus == "13" || item.productQty == 0 || item.qty>item.productQty) {
      return true;
    } else return false;
  }

  renderCartItems = (item, index, invalid) => {
    const { windowWidth } = this.state;
    const { symbol } = this.props;
    return (
      <tbody key={index}>
        <tr>
          <td className="p-0" style={{ border: 0 }}>
            {this.checkInvalid(item) ? <span className="badge badge-danger p-2 pl-3 pr-3 invalid-item">{item.stockStatus == "13" || item.productQty == 0 ? "Out Of Stock" : "Invalid"}</span> : null}
            <a hidden={!invalid} type="button" className="icon" style={removeBtn(windowWidth)} onClick={() => this.props.removeFromCart(item, this.props)}>
              <i className="fa fa-window-close" />
            </a>
          </td>
        </tr>
        <tr style={{ opacity: this.checkInvalid(item) ? 0.5 : 1 }}>
          <td>
            <Link className={invalid ? "disabled-link" : ""} to={`${process.env.PUBLIC_URL}/product/${item.slug}`}>
              <img ref={(img) => (this[`${item.cid}_ref`] = img)} onError={() => (this[`${item.cid}_ref`].src = noImg)} src={item.image ? item.image : noImg} alt="" />
            </Link>
          </td>
          <td style={windowWidth <= 767 && invalid ? { width: "65%" } : {}}>
            <div className="row">
              <Link className={invalid ? "disabled-link" : ""} to={`${process.env.PUBLIC_URL}/product/${item.slug}`} style={{ width: windowWidth <= 767 ? "90%" : "100%" }}>
                <div>{item.name + " "}</div>
                {item.selected_pov ? "(" + item.selected_pov.attribute1 + (item.selected_pov.attribute2 ? "+ " + item.selected_pov.attribute2 : "") : null}
                {item.selected_pov ? ")" : null}
              </Link>
              <div className="pt-1" hidden={invalid || windowWidth > 767 ? true : false}>
                <a type="button" className="icon" style={{ WebkitAppearance: "none", cursor: "pointer" }} onClick={() => this.props.removeFromCart(item, this.props)}>
                  <i className="icon-close" />
                </a>
              </div>
            </div>
            {/* mobile view */}
            {invalid ? (
              <div className="mobile-cart-content row">
                <div className="col-12 ">
                  <div className="input-group">
                    <span className="input-group-prepend"></span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mobile-cart-content row " style={{ alignItems: "center", flexWrap: "wrap" }}>
                <div className={windowWidth < 500 ? "col-12 mb-2" : "col-7"} style={{ alignItems: "center" }}>
                  <div className="qty-box" style={{ borderWidth: 0 }}>
                    <div className="input-group">
                      <span className="input-group-prepend">
                        <button
                          type="button"
                          disabled={item.qty == 1 || invalid}
                          className="btn quantity-left-minus"
                          onClick={() => this.props.decrementQty(item, this.props)}
                          data-type="minus"
                          data-field="">
                          <i className="fa fa-angle-left" />
                        </button>
                      </span>
                      <input
                        type="text"
                        disabled={invalid}
                        onBlur={() => (document.getElementById("qty_" + item.cid).value.length == 0 ? (document.getElementById("qty_" + item.cid).value = this.state.prevQty) : {})}
                        style={{ width: "40px" }}
                        name="quantity"
                        min="0"
                        value={item.qty}
                        onChange={(e) => (e.target.value <= 300 ? this.onChangeQty(e, item) : null)}
                        className="form-control input-number"
                      />
                      <span className="input-group-prepend" style={{ marginLeft: -1 }}>
                        <button className="btn quantity-right-plus" onClick={() => this.props.incrementQty(item, 1, this.props)} data-type="plus" disabled={item.qty >= 100 || invalid ? true : false}>
                          <i className="fa fa-angle-right" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={windowWidth < 500 ? "col-12 text-right pr-2" : "col-4 text-right pr-2"}>
                  <h2 className="td-color">
                    {symbol}
                    {item.finalPrice}
                  </h2>
                </div>
              </div>
            )}
          </td>
          <td>
            <h4 style={oriPrice}>
              {symbol}
              {item.finalPrice}
            </h4>
          </td>
          <td>
            <div className="qty-box" hidden={invalid}>
              <div className="input-group">
                <span className="input-group-prepend">
                  <button
                    type="button"
                    disabled={invalid}
                    className="btn quantity-left-minus"
                    onClick={item.qty == 1 ? () => this.onOpenModal(item) : () => this.props.decrementQty(item, this.props)}
                    data-type="minus"
                    data-field="">
                    <i className="fa fa-angle-left" />
                  </button>
                </span>
                <input
                  type="number"
                  id={"qty_" + item.cid}
                  onBlur={() => (document.getElementById("qty_" + item.cid).value.length == 0 ? (document.getElementById("qty_" + item.cid).value = this.state.prevQty) : {})}
                  disabled={invalid}
                  name="quantity"
                  value={item.qty}
                  min="0"
                  onChange={(e) => (e.target.value <= 300 ? this.onChangeQty(e, item) : null)}
                  style={{ backgroundColor: "#ffffff" }}
                  className="form-control input-number"
                />

                <span className="input-group-prepend" style={{ marginLeft: -1 }}>
                  <button className="btn quantity-right-plus" onClick={() => this.props.incrementQty(item, 1, this.props)} data-type="plus" disabled={item.qty >= 100 || invalid ? true : false}>
                    <i className="fa fa-angle-right" />
                  </button>
                </span>
              </div>
            </div>
          </td>
          <td>
            <a hidden={invalid} type="button" className="icon" style={{ cursor: "pointer", WebkitAppearance: "none" }} onClick={() => this.props.removeFromCart(item, this.props)}>
              <i className="fa fa-times" />
            </a>
          </td>
          <td>
            <h4 className="td-color" hidden={invalid}>
              {symbol}
              {parseFloat(item.sum).toFixed(2)}
            </h4>
          </td>
        </tr>
      </tbody>
    );
  };

  renderRemoveConfirmation = () => {
    const { removeItem, openModal } = this.state;
    return (
      <Modal open={openModal} closeOnOverlayClick={false} onClose={() => this.onCloseModal(removeItem)} center>
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <h3 className="text-center" style={{ color: "black" }}>
              Confirmation
            </h3>
            <div className="modal-body" style={modalBox}>
              <p style={modalFont}>Are you sure to remove this item?</p>
              {removeItem ? (
                <p style={modalFont}>
                  {removeItem.name}
                  {removeItem.selected_pov ? "(" + removeItem.selected_pov.attribute1 + (removeItem.selected_pov.attribute2 ? "+ " + removeItem.selected_pov.attribute2 : "") : null}
                  {removeItem.selected_pov ? ")" : null}
                </p>
              ) : null}

              <div className="mt-2 d-inline-flex">
                <button
                  className="btn btn-solid mr-2"
                  style={smallBtn}
                  onClick={() => {
                    this.props.removeFromCart(removeItem, this.props);
                    this.onCloseModal(removeItem);
                  }}>
                  Remove
                </button>
                <button className="btn btn-solid" style={smallBtn} onClick={() => this.onCloseModal(removeItem)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { symbol, total, cartItems,cartInfo,token } = this.props;
    const activeItems = cartItems.filter((item) => item.buyable && item.qty<=item.productQty);
    const inactiveItems = cartItems.filter((item) => !item.buyable || item.qty>item.productQty);
    return (
      <div>
        {this.renderRemoveConfirmation()}

        <Breadcrumb title={"Cart Page"} />
        {cartItems.length > 0 ? (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                
                <div className="col-sm-12">
                  <table className="table cart-table table-responsive-xs">
                    <thead>
                      <tr className="table-head">
                        <th scope="col">image</th>
                        <th scope="col">product name</th>
                        <th scope="col">price</th>
                        <th scope="col">quantity</th>
                        <th scope="col">action</th>
                        <th scope="col">total</th>
                      </tr>
                    </thead>
                    {activeItems.map((item, index) => {
                      return this.renderCartItems(item, index);
                    })}
                  </table>
                  <table className="table cart-table table-responsive-xs">
                    {inactiveItems.map((item, index) => {
                      return this.renderCartItems(item, index, true);
                    })}
                  </table>
                  <table className="table cart-table table-responsive-md" hidden={activeItems.length == 0}>
                    <tfoot>
                      <tr>
                        <td>total price :</td>
                        <td>
                          <h2 className="text-nowrap">{symbol + " " + parseFloat(total).toFixed(2)}</h2>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="row cart-buttons">
                <div className="col-6">
                  {/* <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} className="btn btn-solid">
                    empty cart
                  </Link> */}</div>
                <div className="col-6" hidden={activeItems.length == 0}>
                  <Link to={(cartInfo.guestCheckout || token)?`${process.env.PUBLIC_URL}/checkout`:'/login'} className="btn btn-solid">
                    check out
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space">
            <div className="container mb-5 mt-5">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                      <h3>
                        <strong>Your Cart is Empty</strong>
                      </h3>
                      <h4>Explore more shortlist some items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const smallBtn = {
  padding: "10px 26px",
};

const removeBtn = (windowWidth) => {
  return {
    position: "absolute",
    right: windowWidth < 767 ? 25 : 60,
    marginTop: 40,
    cursor: "pointer",
    zIndex: 10,
    color: "red",
    fontSize: 22,
  };
};

const modalFont = {
  textTransform: "none",
  fontSize: 16,
  color: "#555555",
};

const oriPrice = {
  color: "#222222",
};

const modalBox = {
  padding: 30,
  textAlign: "center",
  borderWidth: 1,
};

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart.sort(function compare(a, b) {
    var dateA = new Date(a.cdate);
    var dateB = new Date(b.cdate);
    return dateB - dateA;
  }),
  token: state.auth.token,
  symbol: state.data.symbol,
  cartInfo: state.data.cartInfo,
  total: getCartTotal(state.cartList.cart),
});

export default connect(mapStateToProps, { removeFromCart, incrementQty, decrementQty, updateToken, addMyCart, addToCart })(cartComponent);
