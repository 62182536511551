import React, { Component } from "react";
import { Link } from "react-router-dom";
import { convertToCartItem } from "../../services/index";
import { connect } from "react-redux";
import * as GENERAL from "../../api/general";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

class DetailsWithPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: 1,
      stock: "InStock",
      nav3: null,
      product: this.props.item,
      selectedOptionID: this.props.item.data_option ? "0" : "00",
      selectedExtraPrice: 0,
      optionList: this.props.item.data_option,
      displayPrice:
        this.props.item.is_promotion == 1 && this.props.item.price.pprice > 0 && this.props.item.price.rprice !== this.props.item.price.pprice
          ? this.props.item.price.pprice
          : this.props.item.price.rprice,
      copied: false,
    };
  }

  componentDidMount() {
    let item = this.state.product;
    item.finalPrice = parseFloat(this.state.displayPrice).toFixed(2);
    item.qty = 1;
    item.pid = item.id;
    let retailPrice = parseFloat(this.props.item.price.rprice).toFixed(2);
    let promoPrice = parseFloat(this.props.item.price.pprice).toFixed(2);
    let different = retailPrice - promoPrice;
    if (different > 0) {
      let percent = (different / retailPrice) * 100;
      this.setState({
        discount: parseFloat(percent).toFixed(0),
      });
    }
    this.setState({
      product: item,
      nav3: this.slider3,
    });
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1, product: { ...this.state.product, qty: this.state.quantity - 1 } });
    }
  };

  plusQty = () => {
    if (this.state.quantity ) {
      this.setState({ quantity: this.state.quantity + 1, product: { ...this.state.product, qty: this.state.quantity + 1 } }, () => {});
    } else {
      // this.setState({stock: 'Out of Stock !'})
    }
  };

  changeQty = (e) => {

      this.setState({ quantity: e.target.value >= 1 ? parseInt(e.target.value) : 1 });

  };

  handleChange(option) {
    this.setState({ selectedOptionID: option.id, selectedExtraPrice: option.extra_price });
    let oriPrice = parseFloat(
      this.props.item.is_promotion == 1 && this.props.item.price.pprice > 0 && this.props.item.price.rprice !== this.props.item.price.pprice
        ? this.props.item.price.pprice
        : this.props.item.price.rprice
    );
    let extraPrice = parseFloat(option.extra_price);
    let total = oriPrice + extraPrice;
    let item = this.state.product;
    item.finalPrice = parseFloat(total).toFixed(2);
    item.selected_pov = option;
    this.setState({
      displayPrice: parseFloat(total).toFixed(2),
      product: item,
    });
  }

  handleShare = () => {
    let item = this.state.product;
    if (navigator.canShare && navigator.canShare({ url: item.referral_link })) {
      navigator.share({
        url: item.referral_link,
        title: 'Share',
        text: 'Share to others.',
      })
      .then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  render() {
    const { symbol, item, addToCartClicked, BuynowClicked, history, isDealer } = this.props;
    const { discount, selectedExtraPrice } = this.state;
    const canShare = navigator.canShare;
    return (
      <div className="col-lg-6 rtl-text">
        <div className="product-right">
          <h2>
            {" "}
            {item.name}
            {item.short_descr ? (<p>{item.short_descr}</p>) : null}
            {item.combo ? (
              <div ref={(prod) => (this[`${item.pid}_ref`] = prod)} className="combo-list">
                {item.combo.map((comboItem) => {
                  return (
                    <p key={comboItem.id} className="mb-0">
                      - {comboItem.product_name}
                    </p>
                  );
                })}
              </div>
            ) : null}
            {item.stock_status == 2 ? (
              <div className="badge text-white bg-theme ml-2" style={label}>
                New
              </div>
            ) : (
              ""
            )}
          </h2>
          {item.is_promotion == 1 && parseFloat(item.price.pprice) > 0 && item.price.rprice !== item.price.pprice ? (
            <h4>
              <del>
                {symbol}
                {item.price.rprice}
              </del>
              <span className="badge text-white bg-theme ml-2">{discount}% off</span>
            </h4>
          ) : null}
          {isDealer && item.dealer_price && parseFloat(item.dealer_price) > 0 ? (
            <>
              <div className="d-inline-flex align-items-center">
                <div className="mr-1 font-weight-bold">Dealership Price : </div>
                <h3 className="text-primary mb-2 pt-2">
                  {symbol}
                  {(Math.round((parseFloat(item.dealer_price) + parseFloat(selectedExtraPrice)) * 100) / 100).toFixed(2)}
                </h3>
              </div>
              <p className="mb-2">
                Normal Price : {symbol}
                {this.state.displayPrice}
              </p>
            </>
          ) : (
            <h3>
              {symbol}
              {this.state.displayPrice}
            </h3>
          )}

          <div className="product-description border-product">
            {item.stock_status !== "1" && item.stock_status !== "2" ? null : (
              <>
                {item.data_option ? (
                  <div style={{ marginBottom: 10 }}>
                    <ul>
                      {item.data_option.map((option, i) => {
                        return (
                          <li key={i} className={option.id == this.state.selectedOptionID ? "option-box bg-theme" : "option-box"}>
                            <a
                              type="button"
                              style={option.id == this.state.selectedOptionID ? { color: "#FFFFFF", WebkitAppearance: "none" } : { color: "#222", WebkitAppearance: "none" }}
                              onClick={() => this.handleChange(option)}>
                              {option.attribute1 + " " + (option.attribute2 ? "+ " + option.attribute2 : "")}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null}
                <h6 className="product-title">quantity</h6>
                <div className="qty-box">
                  <div className="input-group">
                    <span className="input-group-prepend">
                      <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </span>
                    <input type="text" required name="quantity" value={this.state.quantity} onChange={this.changeQty} maxLength={3} className="form-control input-number" />
                    <span className="input-group-prepend">
                      <button ref="btn" type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>

          {(item.stock_status == "1" || item.stock_status == "2" )? (
            <div className="product-buttons">
              <button
                className={this.state.selectedOptionID == "0" ? "btn btn-disabled" : "btn btn-solid"}
                disabled={this.state.selectedOptionID == "0"}
                onClick={this.state.selectedOptionID == "0" ? null : () => addToCartClicked(convertToCartItem(this.state.product), this.state.quantity)}>
                add to cart
              </button>
              {this.state.selectedOptionID == "0" ? (
                <button className="btn btn-disabled" disabled={true}>
                  buy now
                </button>
              ) : (
                <Link to={`${process.env.PUBLIC_URL}/checkout`} className={"btn btn-solid"} onClick={() => BuynowClicked(this.state.product, this.state.quantity)}>
                  buy now
                </Link>
              )}
            </div>
          ) : (
            <div className="product-buttons">
              <button
                onClick={item.stock_status == "16" ? () => history.push({ pathname: "/contact-us", state: { productName: item.name } }) : {}}
                disabled={item.stock_status !== "16"}
                className={item.stock_status == "16" ? "btn btn-solid ml-0" : "btn btn-disabled ml-0"}>
                {GENERAL.returnStatusName(item.stock_status)?GENERAL.returnStatusName(item.stock_status):'Out Of Stock'}
              </button>
            </div>
          )}
          {item.warranty ? (<span>Warranty: {item.warranty}</span>) : null}
          {item.referral_link && item.referral_link.length > 0 ? (
            canShare ? (
              <>
                <hr />
                  <button onClick={this.handleShare} className="btn btn-primary medium ml-0">Share</button>
              </>
            ) : (
            <>
              <hr />
              <CopyToClipboard text={item.referral_link} onCopy={() => toast.success("Link has been copied to clipboard", { autoClose: 3000, position: "top-center" })}>
                <button className="btn btn-primary medium ml-0">Share</button>
              </CopyToClipboard>
            </>
          )) : null}
        </div>
      </div>
    );
  }
}

const label = {
  fontSize: 13,
  position: "relative",
  top: -4,
};

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
});

export default connect(mapStateToProps, null)(DetailsWithPrice);
