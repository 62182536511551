import React, {Component} from 'react';
import * as General from "../../api/general";
import { toast } from 'react-toastify';

class Contact extends Component {

    constructor (props) {
        super (props);
        this.state = {
            loading:true,
            address1: '',
            address2: '',
            email: '',
            officeno: '',
            mobileno: '',
            faxno: '',
            // enquiry field
            eName: '',
            eEmail: '',
            eMobileno: '',
            eTitle: '',
            eMssg: ''
        }
        this.getGeneralInfo();
    }

    componentDidMount(){
        let locationState = this.props.PROPS.location.state
        if(locationState && locationState.productName ){
            this.setState({
                eTitle: 'Product Enquiry',
                eMssg:'I would like to ask about '+ locationState.productName+', '
            })
        }
    }

    getGeneralInfo(){
        General.getSystemSettingsGeneral().then((res) => {
            this.setState({
                address1: res.data.address1.value,
                address2: res.data.address2.value,
                officeno: res.data.office_no_1.value,
                mobileno: res.data.mobile_no_1.value,
                faxno: res.data.fax_no.value,
                email: res.data.e_mail.value,
                loading:false,
            })
          }).catch(err => {

          })
    }

    handleSubmit(e){
        e.preventDefault();
        const enquiryData = new FormData();
        enquiryData.append('name', this.state.eName);
        enquiryData.append('email', this.state.eEmail);
        enquiryData.append('contact_no', this.state.eMobileno);
        enquiryData.append('title', this.state.eTitle);
        enquiryData.append('message', this.state.eMssg);
        General.sendEnquiry(enquiryData).then((res) => {
            toast.success("Enquiry Sent Successfully!", { autoClose: 3000 ,position:'top-center'});
            this.setState({
                eName: '',
                eEmail: '',
                eMobileno: '',
                eTitle: '',
                eMssg: ''
            })
        }).catch(err => {
            console.log(err);
            toast.error(err.message, { autoClose: 3000 ,position:'top-center'});
        })
    }

    render (){
        const {loading } = this.state
        return (
            !loading?
            <div>
                <section className=" contact-page section-b-space">
                    <div className="container">
                        <div className="row section-b-space">
                            <div className="col-lg-7 map">
                            <iframe
                                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJueydTQm1zTERDzV5wLP8Aac&key=AIzaSyC7uou3aJ_a5TotZlZW7Y7r-h-TSyouWFo"
                                allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-5">
                                <div className="contact-right">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-phone text-theme"></i>
                                                <h6 className="text-theme" >Contact Us</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>{this.state.officeno}</p>
                                                <p>{this.state.mobileno}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker text-theme" aria-hidden="true"></i>
                                                <h6 className="text-theme" >Address</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>{this.state.address1}</p>
                                                <p>{this.state.address2}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i class="fa fa-envelope text-theme"></i>
                                                <h6 className="text-theme" >Email</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>{this.state.email}</p>
                                            </div>
                                        </li>
                                        {this.state.faxno? <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-fax text-theme" aria-hidden="true"></i>
                                                <h6 className="text-theme">Fax</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>{this.state.faxno}</p>
                                            </div>
                                        </li>: null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <form className="theme-form" onSubmit={(e)=>this.handleSubmit(e)}>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="name">Name</label>
                                            <input onChange={(e)=>this.setState({eName : e.target.value})} value={this.state.eName} type="text" className="form-control" id="name"
                                                   placeholder="Enter Your name" required="" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email">Email</label>
                                            <input onChange={(e)=>this.setState({eEmail : e.target.value})} value={this.state.eEmail} type="text" className="form-control" id="email"
                                                   placeholder="Email" required="" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="review">Mobile Number</label>
                                            <input onChange={(e)=>this.setState({eMobileno : e.target.value})} value={this.state.eMobileno} type="text" className="form-control" id="mobileno"
                                                   placeholder="Enter your number" required="" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email">Title</label>
                                            <input onChange={(e)=>this.setState({eTitle : e.target.value})} value={this.state.eTitle} type="text" className="form-control" id="title" placeholder="Enquiry Title"
                                                   required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="review">Write Your Message</label>
                                            <textarea onChange={(e)=>this.setState({eMssg : e.target.value})} value={this.state.eMssg} className="form-control" placeholder="Write Your Message"
                                                      id="mssg" rows="6"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-solid" type="submit">Send Your Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            :<div style={loadingBar} className="loading-cls"></div>
        )
    }
}
const loadingBar = {
    marginTop: '120px',
    marginBottom:'120px'
}

export default Contact