import React, { Component } from "react";
import { filterSort } from "../../../actions";

class FilterBar extends Component {
  constructor (props) {
    super (props)
    this.state={
        currentSort:'Newest'
    }
  }

  componentDidMount(){
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
      this.setState({windowWidth: window.innerWidth});
      if(window.innerWidth<991 && document.querySelector(".product-wrapper-grid") &&
        document.querySelector(".product-wrapper-grid").classList.contains('list-view')){
          this.gridLayout()
      }
  }
  //List Layout View
  listLayout() {
    document.querySelector(".collection-grid-view ul").style = "display:none";
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-12");
    });
    setTimeout(function () {
      document.querySelector(".product-wrapper-grid").style = "opacity: 1";
    }, 500);
    this.props.changeMode('list')
  }

  //Grid Layout View
  gridLayout() {
    document.querySelector(".collection-grid-view ul").style = "display:flex";
    document.querySelector(".product-wrapper-grid").classList.remove("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-2");
    });
    if(this.state.windowWidth<991){
      this.props.onLayoutViewClicked(3);
    }else{
      this.props.onLayoutViewClicked(2);
    }
    this.props.changeMode('grid')
  }

  // Layout Column View
  LayoutView = (colSize) => {
    if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
      var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
      [].forEach.call(elems, function (el) {
        el.className = "";
        el.classList.add("col-xl-" + colSize);
      });
    }

    this.props.onLayoutViewClicked(colSize);
  };

  render() {
    const { pagination,currentSort,windowWidth } = this.props

    return (
      <div className="product-filter-content">
        <div className="search-count">
          <h5>Showing Products 1-{pagination.total} Result</h5>
        </div>
        <div className="collection-view">
            <ul>
                <li><i
                    className="fa fa-th grid-layout-view" onClick={()=>this.gridLayout()}></i>
                </li>
                <li><i
                    className="fa fa-list-ul list-layout-view" onClick={()=>this.listLayout()}></i>
                </li>
            </ul>
        </div>
        <div className="collection-grid-view">
            <ul>
                <li>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`}
                    alt="" 
                    className="product-6-layout-view" onClick={() => this.LayoutView(2)} />
                </li>
                <li>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`}
                        alt=""
                        className="product-4-layout-view" onClick={() => this.LayoutView(3)} />
                </li>
                <li>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`}
                        alt=""
                        className="product-3-layout-view" onClick={() => this.LayoutView(4)} />
                </li>
                {/* <li>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`}
                        alt=""
                        className="product-6-layout-view" onClick={() => this.LayoutView(2)} />
                </li> */}
            </ul>
        </div>
        <div className="product-page-filter">
            <select value={currentSort} onChange={(e) => this.props.updateSorting(e.target.value)}>
                <option value="Newest">Newest Items</option>
                <option value="AscPrice">Sort By Price: Low To High</option>
                <option value="DescPrice">Sort By Price: High To Low</option>
                <option value="AscName">Sort By Name: A To Z</option>
                <option value="DescName">Sort By Name: Z To A</option>
            </select>
        </div>
      </div>
    );
  }
}


export default FilterBar;
