import React from 'react';
import {Link} from 'react-router-dom'

function LogoImage(props) {

    return <Link to={`${process.env.PUBLIC_URL}/`} >
        {props.from=='header' && props.windowWidth <= 490?
         <img src={`${process.env.PUBLIC_URL}/favicon.ico` } 
            alt="" style={{height:'34px',width:'auto'}} />
        :
        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/${props.logo}` } 
         alt="" style={{width:props.from=='header'?props.windowWidth<768?'200px':'230px':'240px',height:'auto'}} />
        }
        </Link>;
}

export default LogoImage;