import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from "../../../api/profile";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as AUTH from "../../../api/auth";
import { updateToken, addMyCart, refreshProfile } from "../../../actions/index";
import { connect } from "react-redux";
import defaultAvatar from "../../../assets/images/defaultAvatar.jpg";
import SideMenu from "../sidemenu";
const mobileReg = /^[0-9\b]+$/;
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      gender: "",
      mobileno: "",
      failMsg: "",
      imgFailMsg: "",
      updateErr: false,
      isOpen: false,
      avatarFile: null,
      loading: true,
    };
    this.inputRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.getProfileInfo();
  }

  getProfileInfo() {
    Profile.getProfile()
      .then((res) => {
        this.setState({
          fname: res.data.fname,
          lname: res.data.lname,
          email: res.data.email,
          gender: res.data.gender,
          mobileno: res.data.mobileno,
          avatarUrl: res.data.image,
          loading: false,
        });
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        } else {
          toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
        }
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    let valid = true;
    if (!mobileReg.test(this.state.mobileno)) {
      valid = false;
      toast.error("Mobile Number is invalid", { autoClose: 3000, position: "top-center" });
    }
    if (valid) {
      this.setState({ imgFailMsg: "" });
      const profileData = new FormData();
      profileData.append("fname", this.state.fname);
      profileData.append("lname", this.state.lname);
      profileData.append("email", this.state.email);
      profileData.append("gender", this.state.gender);
      profileData.append("mobileno", this.state.mobileno);
      if (this.state.avatarFile) profileData.append("images", this.state.avatarFile);

      Profile.updateProfile(profileData)
        .then((res) => {
          this.props.refreshProfile(true);
          toast.success("Update Profile Successful!", { autoClose: 3000, position: "top-center" });
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          } else {
            let failMsg = "";
            if (err.data) {
              if (err.data.email) {
                failMsg = err.data.email;
                this.setState({
                  updateErr: true,
                  failMsg: failMsg,
                });
              }
              if (err.data.gender) {
                toast.error(err.data.gender, { autoClose: 3000, position: "top-center" });
              }
            } else if (err.message && err.http_code == 500) {
              this.setState({
                imgFailMsg: err.message,
              });
            } else {
              toast.error("Invalid Information Provided", { autoClose: 3000, position: "top-center" });
            }
          }
        });
    }
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleAvatarChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        avatarFile: file,
        avatarUrl: reader.result,
      });
    };
    reader.onerror = (event) => {
      toast.error("File could not be read ", { autoClose: 2000, position: "top-center" });
    };
    if (file) reader.readAsDataURL(file);
  };

  render() {
    const { updateErr, failMsg, avatarUrl, imgFailMsg, loading } = this.state;
    return (
      <div>
        <Breadcrumb title={"Profile"} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab="profile" />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>My Profile</h2>
                    </div>
                    <div className="welcome-msg">
                      <p>Here's your personal profile information.</p>
                    </div>
                    <div className="box-account box-info">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-title"></div>
                            <div className="box-content">
                              {loading ? (
                                <div className="loading-cls" />
                              ) : (
                                <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                                  <div className="form-row justify-content-center pt-2">
                                    <img
                                      ref={(img) => (this.avatar = img)}
                                      onError={() => (this.avatar.src = defaultAvatar)}
                                      src={avatarUrl ? avatarUrl : defaultAvatar}
                                      className="img-fluid "
                                      alt=""
                                      style={avatarStyle}
                                    />
                                    <input
                                      className="fileInput"
                                      type="file"
                                      hidden
                                      ref={this.inputRef}
                                      accept="image/png,image/jpeg,image/jpg"
                                      onChange={(e) => this.handleAvatarChange(e)}
                                    />
                                  </div>
                                  <div className="p-2 text-center">
                                    <a
                                      type="button"
                                      className="text-primary"
                                      onClick={() => this.inputRef.current.click()}
                                      style={{ fontSize: 14, cursor: "pointer", WebkitAppearance: "none" }}>
                                      <i className="mdi mdi-square-edit-outline mr-2"></i>
                                      Edit Avatar
                                    </a>
                                  </div>
                                  <div>{imgFailMsg.length > 0 ? <div style={errMsg}>{imgFailMsg}</div> : null}</div>
                                  <div className="form-row">
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="email">First Name</label>
                                      <input
                                        maxLength={30}
                                        onChange={(e) => this.setState({ fname: e.target.value })}
                                        value={this.state.fname}
                                        type="text"
                                        className="form-control"
                                        id="fname"
                                        placeholder="First Name"
                                        required=""
                                      />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="review">Last Name</label>
                                      <input
                                        maxLength={30}
                                        onChange={(e) => this.setState({ lname: e.target.value })}
                                        value={this.state.lname}
                                        type="text"
                                        className="form-control"
                                        id="lname"
                                        placeholder="Last Name"
                                        required=""
                                      />
                                    </div>
                                  </div>

                                  <div className="form-row">
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="email">Email</label>
                                      <input
                                        disabled
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        value={this.state.email}
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email"
                                        required=""
                                      />
                                      {updateErr ? <div style={errMsg}>{failMsg}</div> : null}
                                    </div>
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="review">Mobile Number</label>
                                      <input
                                        maxLength={18}
                                        onChange={(e) => this.setState({ mobileno: e.target.value })}
                                        value={this.state.mobileno}
                                        type="text"
                                        className="form-control"
                                        id="mobileno"
                                        placeholder="Mobile Number"
                                        required=""
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="review">Gender</label>
                                      <div className="radio">
                                        <input
                                          onChange={(e) => this.setState({ gender: e.target.value })}
                                          checked={this.state.gender === "m"}
                                          type="radio"
                                          value="m"
                                          className="c-pointer"
                                        />
                                        <span className="c-pointer ml-1 mr-4" onClick={() => this.setState({ gender: "m" })}>
                                          Male
                                        </span>

                                        <input
                                          onChange={(e) => this.setState({ gender: e.target.value })}
                                          checked={this.state.gender === "f"}
                                          type="radio"
                                          value="f"
                                          className="c-pointer"
                                        />
                                        <span className="c-pointer ml-1 mr-4" onClick={() => this.setState({ gender: "f" })}>
                                          Female
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ paddingLeft: "5px" }} className="mt-3">
                                      <input type="submit" className="btn btn-solid" value="Update" />
                                    </div>
                                  </div>
                                </form>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const avatarStyle = {
  borderRadius: 130 / 2,
  height: 130,
  width: 130,
  objectFit: "cover",
};

const errMsg = {
  color: "red",
  paddingBottom: 8,
};

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
  refreshProfile: (profile) => dispatch(refreshProfile(profile)),
});

export default connect(null, mapDispatchToProps)(Account);
