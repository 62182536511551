import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from '../../../api/profile';
import * as AUTH from '../../../api/auth';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import {updateToken,addMyCart} from '../../../actions/index'
import { connect } from "react-redux";
import SideMenu from '../sidemenu';

const re = /^\d*(\.\d{1,2})?$/;

class Wallet extends Component {
    constructor (props) {
        super (props);
        this.state = {
            password: '',
            secPassword: '',
            failMsg: '',
            topUpAmt: '',
            ewAmt: '',
            refundAmt: '',
            tpAmt: '',
            paymentUrl: '',
            paymentData: {},
            topUpErr: false
        }
    }

    componentWillMount() {
        this.getWalletAmt();
    }

    getWalletAmt(){
        Profile.getWallet().then((res) => {
            if(!res.errors){
                this.setState({
                    ewAmt: res.data.ewallet,
                    tpAmt: res.data.topup,
                    refundAmt: res.data.refund
                })
            }
        }).catch(err => {
            if(err.http_code==401 || (err.http_code==403 && err.message=='token expired')){
                AUTH.logout(this.props)
                toast.error("Session Expired", { autoClose: 3000 ,position:'top-center'});
                this.props.history.replace('/login')
            }
        })
    }

    updateValue(e){
        this.setState({
            topUpAmt: e
        })
    }

    handleSubmit(e){
        e.preventDefault();
        const topUpData = new FormData();
        let amt = parseFloat(this.state.topUpAmt).toFixed(2);
        let valid = true
        if(!re.test(amt)){
            valid = false
            this.setState({
                topUpErr: true,
                failMsg: 'Top up amount format incorrect, only integer and amount up to 2 decimal point are allowed. (Ex: 10.50)'
            })
        }
        if(amt < 10.00){
            valid = false
            this.setState({
                topUpErr: true,
                failMsg: 'Required a minimum top up amount of RM 10.'
            })
        }
        if(valid === true){
            topUpData.append('amount', amt);
            topUpData.append('to_wallet_type', 'ewallet');
            topUpData.append('payment_channel', 'kp');
            topUpData.append('ttype', '10');
            topUpData.append('uid', 'own');
            Profile.topUpWallet(topUpData).then((res) => {
                this.setState({
                    topUpAmt: '',
                    paymentUrl: res.data.url,
                    paymentData: res.data.data
                },()=>{
                    this.submitBtn.click();
                })
            }).catch(err => {
                console.log(err);
                if(err.http_code==401 || (err.http_code==403 && err.message=='token expired')){
                    AUTH.logout(this.props)
                    toast.error("Session Expired", { autoClose: 3000,position:'top-center' });
                    this.props.history.replace('/login')
                }
            })
        }
    }



    render (){
        const { ewAmt, tpAmt, refundAmt, paymentData, topUpErr, failMsg } = this.state
        return (
            <div>
                <Breadcrumb title={'Credit'}/>
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <SideMenu activeTab={'credit'}/>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>My Credit</h2>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>You may view your credit information and top up your credit here.</p>
                                        </div>
                                        <div className="box-account box-info">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="box">
                                                        <div className="box-title"></div>
                                                        <div className="box-content">
                                                            <div className="row" style={{marginTop: "10px"}}>
                                                                <div style={{textAlign: "center"}} className="col-lg-6 col-12">
                                                                    <div>
                                                                        <p style={{marginBottom: "8px"}}>Amount (RM)</p>
                                                                        <h2>{ewAmt}</h2>
                                                                    </div>
                                                                </div>
                                                                {/* <div style={{textAlign: "center", marginTop: "10px", marginBottom: "10px"}} className="col-lg-3 col-6">
                                                                    <div className="theme-border-box" style={{paddingTop: "8px", paddingBottom:"8px"}}>
                                                                        <p style={{marginBottom: "5px"}}>Top Up (RM)</p>
                                                                        <h4 style={{margin: "0px"}}>{tpAmt}</h4>
                                                                    </div>
                                                                </div>
                                                                <div style={{textAlign: "center", marginTop: "10px", marginBottom: "10px", paddingLeft: "0px"}} className="col-lg-3 col-6">
                                                                    <div style={{border: "1px solid #ffcb04", paddingTop: "8px", paddingBottom:"8px"}}>
                                                                        <p style={{marginBottom: "5px"}}>Refund (RM)</p>
                                                                        <h4 style={{margin: "0px"}}>{refundAmt}</h4>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            {/* <form className="theme-form" onSubmit={(e)=>this.handleSubmit(e)}>
                                                                <div style={orderBox}>
                                                                    <h5>Top Up Credit</h5>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                            <input onChange={(e)=>this.setState({topUpAmt : e.target.value})} className="form-control" value={this.state.topUpAmt} id="topUpAmt"
                                                                            placeholder="Enter preferred amount" required="" />
                                                                            <p style={{marginBottom: "0px"}}>Minimum top up amount is RM 10</p>
                                                                            {topUpErr?
                                                                                <div style={errMsg}>{failMsg}</div>:null
                                                                            }
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                            <div className="row" style={{marginLeft: "3px"}}>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e)=>this.updateValue(10)} style={topUpBtn1}>RM 10</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e)=>this.updateValue(50)}  style={topUpBtn2}>RM 50</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e)=>this.updateValue(100)}  style={topUpBtn3}>RM 100</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <div style={{paddingLeft: '5px'}} className="mt-3">
                                                                            <input type="submit" className="btn btn-solid" value="Confirm"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>               */}
                                                     </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <form method="post" action={this.state.paymentUrl}>
                    <input type="hidden" name="ord_mercID" value={paymentData.ord_mercID}/>
                    <input type="hidden" name="ord_date" value={paymentData.ord_date}/>
                    <input type="hidden" name="ord_totalamt" value={paymentData.ord_totalamt}/>
                    <input type="hidden" name="ord_gstamt" value={paymentData.ord_gstamt}/>
                    <input type="hidden" name="ord_shipname" value={paymentData.ord_shipname}/>
                    <input type="hidden" name="ord_shipcountry" value={paymentData.ord_shipcountry}/>
                    <input type="hidden" name="ord_mercref" value={paymentData.ord_mercref}/>
                    <input type="hidden" name="ord_telephone" value={paymentData.ord_telephone}/>
                    <input type="hidden" name="ord_email" value={paymentData.ord_email}/>
                    <input type="hidden" name="ord_delcharges" value={paymentData.ord_delcharges}/>
                    <input type="hidden" name="ord_svccharges" value={paymentData.ord_svccharges}/>
                    <input type="hidden" name="ord_customfield1" value={paymentData.ord_customfield1}/>
                    <input type="hidden" name="ord_customfield2" value={paymentData.ord_customfield2}/>
                    <input type="hidden" name="ord_returnURL" value={paymentData.ord_returnURL}/>
                    <input type="hidden" name="merchant_hashvalue" value={paymentData.merchant_hashvalue}/>
                    <input hidden  ref={btn=>this.submitBtn = btn} type="submit" value="Submit"/>
                </form>
            </div>
        )
    }

}

const topUpBtn1 = {
    padding: "7px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "30px",
    marginRight: "8px"
}

const topUpBtn2 = {
    padding: "7px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "30px",
    marginRight: "8px"
}

const topUpBtn3 = {
    padding: "7px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "30px",
}

const errMsg = {
    color:'red',
    paddingBottom:8,
}

const orderBox = {
    marginTop: '20px',
    border: '1px solid #dddddd',
    padding: '15px',
    marginBottom: '18px'
}

const confirmBtn = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "10px",
    marginTop: "10px"
}

const mapDispatchToProps = dispatch => ({
    updateToken: token => dispatch(updateToken(token)),
    addMyCart: cart => dispatch(addMyCart(cart))
  })

export default connect(null,mapDispatchToProps)(Wallet);