import * as AUTH from './auth'
const axios = require("axios");

export async function getDownlineList(params) {
    let url=process.env.API_URL + process.env.API_PREFIX +'/downline?'
    if(params.per_page)
      url+="&per_page="+params.per_page
    if(params.page)
      url+="&page="+params.page
    if(params.search)
      url+="&search="+params.search
    if(params.dealer_type)
      url+="&dealer_type="+params.dealer_type
    if(params.join_start)
      url+="&join_start="+params.join_start
    if(params.join_end)
      url+="&join_end="+params.join_end
      return new Promise((resolve, reject) => {
      axios
        .get(url,{
          headers: {
            Accept: "application/json",
            'token': AUTH.getAccessToken()
          }
        })
        .then(response => {
          const { data } = response;
          if(data.http_code=='200')
            resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
    });
  }

export async function getDownlineDetails(id) {
  let url=process.env.API_URL + process.env.API_PREFIX +'/downline/'+id
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;

        if(data.http_code=='200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function addDownline(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/downline", data,{
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function updateDownlineDetails(data,id) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/downline/"+id, data,{
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getDealerTypes() {
    let url=process.env.API_URL + process.env.API_PREFIX +'/dealer-type?'
 
      return new Promise((resolve, reject) => {
      axios
        .get(url,{
          headers: {
            Accept: "application/json",
            'token': AUTH.getAccessToken()
          }
        })
        .then(response => {
          const { data } = response;
          if(data.http_code=='200')
            resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
    });
  }

  export async function getDownlineAddressList(id) {
    let url=process.env.API_URL + process.env.API_PREFIX +'/profile/downline-address/'+id;
  
      return new Promise((resolve, reject) => {
      axios
        .get(url,{
          headers: {
            Accept: "application/json",
            'token': AUTH.getAccessToken()
          }
        })
        .then(response => {
          const { data } = response;
          if(data.http_code=='200')
            resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
    });
  }


  export async function getDownlinePrice(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.API_URL + process.env.API_PREFIX +"/downline-price", data,{
          headers: {
            Accept: "application/json",
            'token': AUTH.getAccessToken()
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
    });
  }