import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as PRODUCTS from "../../../../api/product";
import Div100vh from 'react-div-100vh'

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      categories: [],
      mobileView:false
    };
  }

  componentWillMount(){
    PRODUCTS.getCategoryList().then((res) => {
      this.setState({
        categories: res.data,
      });
    });
  }

  componentDidMount() {
    // Then we set the value in the --vh custom property to the root of the document
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    // window.addEventListener("resize", this.resize.bind(this));
    // this.resize();
  }

  closeNav() {
    document.getElementById("BODY").style.overflowY = 'auto'
    var closemyslide = document.getElementById("mySidenav");
    if (closemyslide) closemyslide.classList.remove("open-side");
  }

  handleSubmenu = (event,from,id) => {
    event.preventDefault()

    if (event.target.classList.contains("sub-arrow") && from=='span'){
      event.preventDefault()

      if( document.getElementById([`${id}_level`]).classList.contains("opensub1"))
        document.getElementById([`${id}_level`]).classList.remove("opensub1");
      else{
        let ulElements = document.getElementsByClassName("opensub1")
        for(let i=0; i< ulElements.length; i++){
          ulElements[i].classList.remove("opensub1")
        }
        document.getElementById([`${id}_level`]).classList.add("opensub1");
      }
    }else{
      if (event.target.classList.contains("sub-arrow")) return;
      if (event.target.nextElementSibling.classList.contains("opensub1"))
        event.target.nextElementSibling.classList.remove("opensub1");
      else {
        document.querySelectorAll(".opensub1").forEach(function(value) {
          value.classList.remove("opensub1");
        });
        event.target.nextElementSibling.classList.add("opensub1");
      }
    }
  };
  handleSubTwoMenu = (event,from,id) => {
    event.preventDefault()

    if (event.target.classList.contains("sub-arrow") && from=='span'){
      event.preventDefault()

      if( document.getElementById([`${id}_level`]).classList.contains("opensub2"))
        document.getElementById([`${id}_level`]).classList.remove("opensub2");
      else{
        let ulElements = document.getElementsByClassName("opensub2")
        for(let i=0; i< ulElements.length; i++){
          ulElements[i].classList.remove("opensub2")
        }
        document.getElementById([`${id}_level`]).classList.add("opensub2");
      }
    }else{
      if (event.target.classList.contains("sub-arrow")) return;
      if (event.target.nextElementSibling.classList.contains("opensub2"))
      event.target.nextElementSibling.classList.remove("opensub2");
      else {
        document.querySelectorAll(".opensub2").forEach(function(value) {
          value.classList.remove("opensub2");
        });
        event.target.nextElementSibling.classList.add("opensub2");
      }
    }
  };
  handleSubThreeMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub3"))
      event.target.nextElementSibling.classList.remove("opensub3");
    else {
      document.querySelectorAll(".opensub3").forEach(function(value) {
        value.classList.remove("opensub3");
      });
      event.target.nextElementSibling.classList.add("opensub3");
    }
  };
  handleSubFourMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub4"))
      event.target.nextElementSibling.classList.remove("opensub4");
    else {
      document.querySelectorAll(".opensub4").forEach(function(value) {
        value.classList.remove("opensub4");
      });
      event.target.nextElementSibling.classList.add("opensub4");
    }
  };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensidesubmenu"))
      event.target.nextElementSibling.classList.remove("opensidesubmenu");
    else {
      event.target.nextElementSibling.classList.add("opensidesubmenu");
    }
  };

  render() {
    return (
      <div id="mySidenav" className="sidenav" >
        <a href="javascript:void(0)" className="sidebar-overlay" 
        style={{height:'inherit'}} onClick={this.closeNav} />
        <nav >
          <a onClick={this.closeNav}>
            <div className="sidebar-back text-left">
              <i className="fa fa-angle-left pr-2" aria-hidden="true" /> Back
            </div>
          </a>
          <Div100vh className="link-section" style={sideBar}>
            <ul id="sub-menu" className="sidebar-menu" >
              {this.state.categories.map((category) => {
                return (
                  <li key={category.id}>
                  <Link ref={cat => this[`${category.id}_ref`] = cat} to={category.children.length > 0? 
                    "#":{ pathname: "/product-category/" + category.id, state: { from: "category", category: category.name } }}
                      onClick={category.children.length > 0 ? (e) => this.handleSubmenu(e) : this.closeNav}
                      >
                      <img src={category.icon} style={catIcon} />
                      {category.name}
                     {category.children.length > 0 ?
                      <span onClick={(e) => this.handleSubmenu(e,'span',category.id)}
                        className="sub-arrow" />:null}
                    </Link>
                    {category.children.length > 0 ? (
                      <ul id={[`${category.id}_level`]}>
                        <li key={category.id} >
                          <Link onClick={this.closeNav}
                            to={{ pathname: "/product-category/" + category.id,
                              state: { from: "category", category: category.name }}}>
                            <b className="text-theme">All {category.name}</b>
                          </Link>
                        </li>
                        {category.children.map((firstChild) => {
                          return (
                            <li key={firstChild.id} >
                              <Link 
                                to={firstChild.children.length > 0?
                                  "#":{
                                  pathname: "/product-category/" + firstChild.id,
                                  state: { from: "category", category: firstChild.name },
                                }}
                                onClick={firstChild.children.length > 0 ? (e) => this.handleSubTwoMenu(e) : this.closeNav}
                                >
                                {firstChild.name}
                                {firstChild.children.length > 0 ?
                                <span onClick={(e) => this.handleSubTwoMenu(e,'span',firstChild.id)}  
                                  className="sub-arrow" />:null}
                              </Link>
                              {firstChild.children.length > 0 ? (
                                <ul id={[`${firstChild.id}_level`]}>
                                  <li key={category.id}>
                                    <Link  onClick={this.closeNav}
                                      to={{ pathname: "/product-category/" + firstChild.id,
                                        state: { from: "category", category: firstChild.name }}}>
                                      <b className="text-theme">All {firstChild.name}</b>
                                    </Link>
                                  </li>
                                  {firstChild.children.map((secondChild) => {
                                    return (
                                      <li key={secondChild.id} >
                                        <Link
                                          to={secondChild.children.length > 0?
                                            "#":{
                                            pathname: "/product-category/" + secondChild.id,
                                            state: { from: "category", category: secondChild.name },
                                          }}
                                          onClick={secondChild.children.length > 0 ? (e) => this.handleSubThreeMenu(e) : this.closeNav}>
                                          {secondChild.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </Div100vh>
        </nav>
      </div>
    );
  }
}

const catIcon = {
  height:25,
  width:25,
  marginRight:6,
  cursor:'default'
}

const sideBar = {
  height: 'calc(var(--vh, 1vh) * 90)',
  paddingBottom: '10px',
  overflowY: 'auto',
}

export default SideBar;
