import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import { addToCart,updateToken,addMyCart } from '../../actions'
import ProductItem from './product-item';
import * as PRODUCT from '../../api/product' 
import {convertToCartItem} from '../../services/index';

class RelatedProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      loading: true,
    };
  }

  componentWillMount(){
    PRODUCT.getRelatedProduct(this.props.itemID).then(res=>{
      this.setState({
        productList:res.data?res.data:[]
      })
    })
  }
  
    render (){
        const {PROPS, symbol, addToCart} = this.props;
        const { productList } = this.state;

        return (
            <section className="section-b-space addtocart_count" style={{paddingTop:0}}>
                <div className="container" hidden={productList.length==0}>
                    <div className="row">
                        <div className="col-12 product-related">
                            <h2>related products</h2>
                        </div>
                    </div>
                    <div className="row ">
                      { productList.slice(0,6).map((product, index ) =>
                        <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-6 mb-3">
                            <ProductItem product={product} symbol={symbol} from={'related'}
                              history={PROPS.history}
                              updateToken={PROPS.updateToken}
                              onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                        />
                        </div>)
                      }
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol,
  };
};

export default connect(mapStateToProps, {addToCart,updateToken,addMyCart})(RelatedProduct);
