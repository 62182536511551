import React, { Component } from "react";
import { connect } from "react-redux";
import { addToCart } from "../../actions/index";
import * as PRODUCTS from "../../api/product";
import loading from "../../assets/images/loader.gif";
import { Link } from "react-router-dom";
import noImg from "../../assets/images/defaultProduct.jpg";
import { svgtigerpaw } from "../../services/script";
import Carousel from 'react-grid-carousel'

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      categoryList: [],
      nonFeatureTopCategory: [],
      currentPage: 1,
      moreLoading: false,
      noResult: false,
      loadedMore: false,
    };
  }

  componentWillMount() {
    this.getCategoryList();
    this.getNonFeatureTopCategoryList();
  }

  getCategoryList() {
    let params={
      page:this.state.currentPage,
      per_page:10
    }
      PRODUCTS.getHomeCategoryList(params)
      .then(res => {
        if(res.data){
          this.setState(
            {
              categoryList: res.data//this.state.currentPage == 1 ? (res.data ? res.data : []) : [...this.state.categoryList, ...res.data],
              //totalPage: res.pagination.total_page
            },
            () => {
              this.setState({
                isLoading: false,
                moreLoading: false
              });
            }
          );
        }else{
          this.setState({
            noResult:true,
            isLoading: false,
            moreLoading: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          noResult: true,
          isLoading: false,
          moreLoading: false,
        });
      });
  }

  fetchMore() {
    this.setState(
      {
        //currentPage: this.state.currentPage + 1,
        moreLoading: true
      },
      () => {
        this.getNonFeatureTopCategoryList();
      }
    );
  }

  getNonFeatureTopCategoryList() {
    let params = {
      page:1,
      per_page:30
    }
    PRODUCTS.getNonFeatureTopCategoryList(params)
    .then(res => {
      if(res.data) {
        this.setState(
          {
            nonFeatureTopCategory: res.data.list//this.state.currentPage == 1 ? (res.data ? res.data : []) : [...this.state.categoryList, ...res.data],
            //totalPage: res.pagination.total_page
          },
          () => {
            this.setState({
              isLoading: false,
              moreLoading: false,
              loadedMore: true,
            });
          }
        );
      } else {
        this.setState({
          noResult: true,
          isLoading: false,
          moreLoading: false,
        });
      }
    })
    .catch(err => {
      this.setState({
        noResult: true,
        isLoading: false,
        moreLoading: false,
      });
    });
  }

  viewMoreContent = () => {
    return (
      <>
        {this.state.moreLoading ? (
          <div className="container" style={{ textAlign: "center" }}>
            <img src={loading} style={loaderStyle} alt="" />
          </div>
        ) :
        this.state.currentPage !== this.state.totalPage ? (
          <div className="container text-theme" style={getMoreStyle} onClick={() => this.fetchMore()}>
            <b>
              view more <i className="fa fa-angle-double-down" aria-hidden="true" />
            </b>
          </div>
        ) : null}
      </>
    );
  };

  carouselLeft = () => {
    return (
      <>
        <span type="prev" className="carousel-before"></span>
      </>
    )
  }

  carouselRight = () => {
    return (
      <>
        <span type="next" className="carousel-after"></span>
      </>
    )
  }

  render() {
    const { categoryList,nonFeatureTopCategory,loadedMore } = this.state;
    const { title, subtitle, subcategoryTitle } = this.props;
    const categoryResponsive =
    [
      {
        breakpoint: 1024,
        cols: 3,
        rows: 2,
        loop: true,
        autoplay: 10000
      },
      {
        breakpoint: 576,
        cols: 2,
        rows: 2,
        loop: true,
        autoplay: 10000
      }
    ]
    return (
      <div hidden={categoryList && categoryList.length==0} style={{minHeight:'300px',justifyContent:'center'}} className="light-background-section">
        <div className="title1" style={sectionBox}>
          {subtitle ? <h4>{subtitle}</h4> : ""}
          <h2 className="title-inner1 mb-0" style={{fontSize: 24}}>
            {title}
          </h2>
        </div>
          <section className="section-b-space addtocart_count pt-4" >
            <div className="container">
              <div className="no-slider row">
                {categoryList.map((cat,index)=>{return(
                  <div key={index} className="category-box" style={{marginBottom:'15px'}}>
                    <div className="category-wrapper">
                      <div className="img-wrapper">
                        <div className="front">
                          <Link to={{ pathname: "/product-category/" + cat.id, state: { from: "category", category: cat.name } }}>
                            <img
                              style={{objectFit:'cover'}}
                              ref={img => this[`${"cat-image" + index}_ref`] = img}
                              src={`${cat.wimage ? cat.wimage : noImg}`}
                              onError={() => (this[`${"cat-image" + index}_ref`].src = noImg)}
                              className="img-fluid"
                              alt={cat.name}
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="product-detail">
                        <div>
                          <Link to={{ pathname: "/product-category/" + cat.id, state: { from: "category", category: cat.name } }}>
                            <h6 style={catName}>{cat.name}</h6>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )})}
              </div>
            </div>
          </section>

          <div className="non-feature-category-container container">
            <div className="title2">
              <h2 className="title2-inner mb-0" style={{fontSize: 24}}>
                {subcategoryTitle}
              </h2>
            </div>
            <div className="no-slider row" >
              <Carousel arrowLeft={this.carouselLeft} arrowRight={this.carouselRight} mobileBreakpoint={0} responsiveLayout={categoryResponsive} cols={4} rows={2} loop autoplay={10000}>
                {nonFeatureTopCategory.map((cat, index) => { return (
                  <Carousel.Item scrollSnap={true}>
                    <div key={index} >
                      <Link
                        to={{ pathname: "/product-category/" + cat.id, state: { from: "category", category: cat.name } }}>
                        <div className="non-feature-category-box">
                          <div className="category-icon">
                            <img src={`${cat.image ? cat.image : noImg}`} style={catIcon} />
                          </div>
                          <div className="category-title">
                            <div style={{display: "inline-block", height: "100%", verticalAlign: "middle"}}></div>
                            <h6 style={subCatName}>{cat.name}</h6>
                          </div>
                          <div className="tiger-paw" dangerouslySetInnerHTML={{ __html: svgtigerpaw }} />
                        </div>
                      </Link>
                    </div>
                  </Carousel.Item>
                )})}
              </Carousel>
              {/* {nonFeatureTopCategory.map((cat, index) => { return (
              <div key={index} className="col-xl-3 col-md-6 col-6 column">
                <Link
                  to={{ pathname: "/product-category/" + cat.id, state: { from: "category", category: cat.name } }}>
                  <div className="non-feature-category-box">
                    <div className="category-icon">
                      <img src={`${cat.image ? cat.image : noImg}`} style={catIcon} />
                    </div>
                    <div className="category-title">
                      <div style={{display: "inline-block", height: "100%", verticalAlign: "middle"}}></div>
                      <h6 style={subCatName}>{cat.name}</h6>
                    </div>
                    <div className="tiger-paw" dangerouslySetInnerHTML={{ __html: svgtigerpaw }} />
                  </div>
                </Link>
              </div>
              )})} */}
            </div>
          </div>
      </div>
    );
  }
}

const catIcon = {
  padding: 0,
  marginLeft: 10,
  display: 'inline-block',
}

const sectionBox = {
  fontSize: 24,
  paddingTop: 0
};

const loaderStyle = {
  width: "90px",
  height: "90px",
  backgroundRepeat: "no-repeat",
  margin: "0 auto",
  marginTop:'200px'
};


const getMoreStyle = {
  textAlign: "center",
  fontSize: 20,
  cursor: "pointer",
};

const catName = {
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  fontSize: 14,
  fontWeight: 600,
  color: "#171A1C",
  WebkitBoxOrient: "vertical",
  height: "38px",
  marginBottom: 0,
  padding:4,
  lineHeight: '30px'
};

const subCatName = {
  overflow: "hidden",
  display: "inline-block",
  WebkitLineClamp: 2,
  fontWeight: 600,
  color: "#171A1C",
  verticalAlign: "middle",
  // height: "40px",
  // marginBottom: 2,
  width: '80%',
  padding:4,
  lineHeight: '16px'
}

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol
  };
};

export default connect(
  mapStateToProps,
  { addToCart }
)(Categories);
